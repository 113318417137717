<template>
    <div>
        <div v-show="show" class="treeWrapper">
            <div class="content">
                <div class="top-info">
                    <span>选择名称</span>
                </div>
                <el-input v-model="filterText" placeholder="输入名称" class="filter-input" clearable
                    :validate-event="false" />
                <div>
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="allOpt" style="margin-left: 24px">全选</el-checkbox>
                </div>
                <el-tree ref="treeRef" show-checkbox :data="treeData" node-key="id" :props="data.defaultProps"
                    :filter-node-method="filterNode" :default-expand-all="isShowTree" @check="handleCheck" class="tree" />
            </div>
            <div class="content">
                <div class="top-info">
                    <el-button type="text" style="float: right" @click="clear">清空</el-button>
                </div>
                <div class="list">
                    <p v-for="(item,index) in checkedList" :key="item.value" class="item" @mouseleave="showIndex = ''"
                        @mouseenter="showIndex = index">
                        <span>{{ item.label }}</span>
                        <i v-show="showIndex === index" class="el-icon-close" @click="del(index)" />
                    </p>
                </div>
            </div>
            <div class="btn" style="text-align: right;margin-right: 37px">
                <el-button @click="handleCancel">取消</el-button>
                <el-button type="primary" @click="save">确定</el-button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        data: {
            type: Object
        },
        rulesForm: {
            type: Object
        },
        treeData: {
            type: Array
        },
        classData: {
            type: Array
        },
        isShowTree:{
          type:Boolean,
          default:false
        },
        flag: {
            type: Boolean
        },
        type: {
            type: String
        }
    },
    data() {
        return {
            show: false,
            showIndex: '',
            filterText: '',
            checkedList: [],
            inputValue: '',
            treeLength:0,
            checkAll: false,
            isIndeterminate: true
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.rulesForm[this.data.key]) {
                let nodes = []
                this.rulesForm[this.data.key].split(',').forEach((item) => {
                    const node = this.$refs.treeRef.getNode(item)
                    if (node) {
                        nodes.push(node.data.name)
                    }
                })
                this.inputValue = nodes.toString()
            }
            this.handleClick()
        })
        this.allOptRecursion(this.treeData)
        this.checkedList = []
        console.log('selectlist2----------',this.treeLength,this.checkedList)
    },
    watch: {
        filterText(val) {
            this.$refs.treeRef.filter(val);
        }
    },
    methods: {
        handleCheck() {
            let checkedNodes = this.$refs.treeRef.getCheckedNodes().filter(i => i.organType === '4')
            checkedNodes.forEach(item => {
                const res = this.classData.find(i => item.parentOrg == i.id)
                item.names = res.name +'/' + item.name
                const res2 = this.classData.find(i => res.parentOrg == i.id)
                if (res2) {
                    item.names = res2.name + '/' + item.names 
                }
            })
            this.checkedList = checkedNodes.map(i => ({ label: i.names, value: i.id }))
            this.checkAll = checkedNodes.length == this.treeLength
            this.isIndeterminate = checkedNodes.length > 0 && checkedNodes.length < this.treeLength
            this.$emit('setCheckedList', this.checkedList)
        },
        save() {
            this.show = false
            this.inputValue = this.checkedList.map(i => i.label).toString()
            this.rulesForm[this.data.key] = this.checkedList.map(i => i.value).toString()
            this.$emit('saveSelectList', this.data.key, this.inputValue, this.type)
        },
        handleClick() {
            this.filterText = ''
            this.$refs.treeRef.setCheckedKeys(this.rulesForm[this.data.key].split(','))
            this.handleCheck()
            this.show = true
        },
        handleCancel() {
            this.show = false
            this.$emit('handleTreeDialogClose')
        },
        // 删除选中
        del(index) {
            this.checkAll = false,
            this.isIndeterminate = true
            this.checkedList.splice(index, 1)
            if (this.checkedList.length == 0) {
                this.isIndeterminate = false
            }
            this.$refs.treeRef.setCheckedKeys(this.checkedList.map(i => i.value))
        },
        filterNode(value, data) {
            if (!value) return true;
            return data[this.data.defaultProps.label].indexOf(value) !== -1;
        },
        // 清空选中
        clear() {
            this.checkAll = false,
            this.isIndeterminate = false
            // 清空数据
            this.checkedList = []
            this.$refs.treeRef.setCheckedKeys([])
        },
        // 全部选中
        allOpt() {
            this.isIndeterminate = false
            if(this.checkedList.length == this.treeLength){
                this.clear()
                return
            }
            if (this.treeData.length > 0) {
                this.checkedList = []
                this.allOptRecursion(this.treeData)
                this.$emit('setCheckedList', this.checkedList)
                this.checkedList.forEach(item => {
                    if (item.parentOrg != "") {
                        const res = this.classData.find(i => item.parentOrg == i.id)
                        item.label = res.name +'/' + item.label
                        const res2 = this.classData.find(i => res.parentOrg == i.id)
                        if (res2) {
                            item.label = res2.name + '/' + item.label
                        }
                    }
                })
                this.checkedList.map(i => {
                    return {
                        label: i.name,
                        value: i.id,
                    }
                })
                this.$refs.treeRef.setCheckedKeys(this.checkedList.map(v => {
                    return v.value
                }))
            }
        },
        // 递归树形菜单最底层
        allOptRecursion(data) {
            this.treeLength = 0
            data.forEach(v => {
                if (v.children) {
                    this.allOptRecursion(v.children)
                } else if (v.organType === "4") {
                    this.checkedList.push({ label: v.name, value: v.id, parentOrg: v.parentOrg })
                }
            })
            this.treeLength = this.checkedList.length
        }
    }
}
</script>
<style lang="scss" scoped>
.treeWrapper {
    box-sizing: border-box;
    width: 494px;
    margin-top: 12px;
    border-radius: 4px;
    background-color: #fff;

    .top-info {
        padding: 0 20px;
        background-color: #f5f7fa;
        height: 35px;
        line-height: 35px;
        font-size: 13px;
        color: #909399;
    }

    .filter-input {
        margin: 10px;
        width: 205px;
    }

    .content {
        box-sizing: border-box;
        border: 1px solid #E4E7ED;
        border-radius: 3px;
        float: left;
        height: 300px;
        width: 228px;
        margin-bottom: 30px;

        .tree {
            height: 188px;
            overflow: auto;
        }

        .list {
            height: 261px;
            overflow: auto;
            width: 100%;

            .item {
                font-size: 14px;
                color: #606266;
                height: 32px;
                line-height: 32px;
                padding: 0 20px;

                &:hover {
                    background-color: #f5f7fa;
                }
            }

            .el-icon-close {
                color: #409EFF;
                font-weight: 1000;
                float: right;
                line-height: 32px;
                cursor: pointer;
            }
        }
    }
}
</style>
<style>
    .personTree {
        padding: 0;
        border: none;
    }
</style>
